import React from "react";
import News_1 from "../../../../assets/images/Placement/New folder/Img (1).jpg";
import News_2 from "../../../../assets/images/Placement/New folder/Img (1).jpg";

const  NewsCard = ({ image, headline, topic, description, date }) => {
  return (
    <div className="p-3 bg-light border rounded text-center">
      <h5 className="fw-bold border-bottom pb-2">Recent News</h5>
      
      <h6 className="fw-bold border-bottom pb-2">{headline}</h6>
      {image && <img src={image} className="mb-2" alt="News" width={"100%"} height={"100%"} />}
      <h6 className="fw-bold">{topic}</h6>
      <p>{description}</p>
      <p className="text-muted">Date: {date}</p>
    </div>
  );
};

const  DirectorDesk = () => {
  return (
    
  <>
      <NewsCard
      image={News_2}
      headline="Placement Drive "
      topic=""
      description=" Our college successfully organized a Placement Drive on January 29th, providing students with excellent career opportunities. More than four reputed companies participated in the event, offering positions across various domains."
      date=" 29-01-25"
    />
      <NewsCard
      image={News_1}
      headline="Training & Placement News"
      topic="Leadership Quality in YOU!"
      description="Empowering Future Leaders: Leadership Event for Aspiring Young Women. Join us for an inspiring session on leadership growth."
      date="27 Jan 2025"
    />
  </>
  );
};

export default  DirectorDesk;
