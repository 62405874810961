import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./ImageGallery.css";
import PlacementImg_1 from "../../../../assets/images/Placement/New folder/Img (1).jpg";
import PlacementImg_2 from "../../../../assets/images/Placement/New folder/Img (2).jpg";
import PlacementImg_3 from "../../../../assets/images/Placement/New folder/Img (3).jpg";
import PlacementImg_4 from "../../../../assets/images/Placement/New folder/Img (4).jpg";
import PlacementImg_5 from "../../../../assets/images/Placement/New folder/Img (5).jpg";

const ImageGallery = () => {
  return (
    <div className="p-3 mt-3 bg-light border rounded">
      <h5 className="fw-bold border-bottom pb-2">Gallery</h5>
      <Carousel autoPlay infiniteLoop showThumbs={false} showStatus={false} interval={2000}>
        <div><img src={PlacementImg_1} alt="Event 1" /></div>
        <div><img src={PlacementImg_2} alt="Event 2" /></div>
        <div><img src={PlacementImg_3} alt="Event 3" /></div>
        <div><img src={PlacementImg_4} alt="Event 3" /></div>
        <div><img src={PlacementImg_5} alt="Event 3" /></div>
      </Carousel>
    </div>
  );
};

export default ImageGallery;
