import React from "react";
import { Helmet } from "react-helmet-async";
import EventContainer from "../../layouts/eventcontainer/eventContainer";
import { faGraduationCap } from "@fortawesome/free-solid-svg-icons";
import "./style.css";
import Pongal2k25_Img_1 from "../../../assets/images/gallery/2025/jan/pongal festival/Img  (1).jpeg";
import Pongal2k25_Img_2 from "../../../assets/images/gallery/2025/jan/pongal festival/Img  (2).jpeg";
import Pongal2k25_Img_3 from "../../../assets/images/gallery/2025/jan/pongal festival/Img  (3).jpeg";
import Pongal2k25_Img_4 from "../../../assets/images/gallery/2025/jan/pongal festival/Img  (4).jpeg";
import Pongal2k25_Img_5 from "../../../assets/images/gallery/2025/jan/pongal festival/Img  (5).jpeg";
import Pongal2k25_Img_6 from "../../../assets/images/gallery/2025/jan/pongal festival/Img  (6).jpeg";
import Pongal2k25_Img_7 from "../../../assets/images/gallery/2025/jan/pongal festival/Img  (7).jpeg";

import ScienceExpo_Img_1 from "../../../assets/images/gallery/2025/jan/sci/Sci (1).jpeg";
import ScienceExpo_Img_2 from "../../../assets/images/gallery/2025/jan/sci/Sci (2).jpeg";
import ScienceExpo_Img_3 from "../../../assets/images/gallery/2025/jan/sci/Sci (3).jpeg";
import ScienceExpo_Img_4 from "../../../assets/images/gallery/2025/jan/sci/Sci (4).jpeg";
import ScienceExpo_Img_5 from "../../../assets/images/gallery/2025/jan/sci/Sci (5).jpeg";
import ScienceExpo_Img_6 from "../../../assets/images/gallery/2025/jan/sci/Sci (5).JPG";
import ScienceExpo_Img_7 from "../../../assets/images/gallery/2025/jan/sci/Sci (1).JPG";

import PlacementTrainning_Img_1 from "../../../assets/images/Placement/New folder/Img (1).jpg";
import PlacementTrainning_Img_2 from "../../../assets/images/Placement/New folder/Img (2).jpg";
import PlacementTrainning_Img_3 from "../../../assets/images/Placement/New folder/Img (3).jpg";
import PlacementTrainning_Img_4 from "../../../assets/images/Placement/New folder/Img (4).jpg";
import PlacementTrainning_Img_5 from "../../../assets/images/Placement/New folder/Img (5).jpg";

import  GalleryPage from "./components/pages/GalleryPage";

const Moments2025 = () => {
  const Pongal2k25 = [
    {
      image: Pongal2k25_Img_1,
      altText: "",
      caption: "",
      id: "pongal2k25",
    },
    {
      image: Pongal2k25_Img_2,
      altText: "",
      caption: "",
      id: "pongal2k25",
    },
    {
      image: Pongal2k25_Img_3,
      altText: "",
      caption: "",
      id: "pongal2k25",
    },
    {
      image: Pongal2k25_Img_4,
      altText: "",
      caption: "",
      id: "pongal2k25",
    },
    {
      image: Pongal2k25_Img_6,
      altText: "",
      caption: "",
      id: "pongal2k25",
    },
    {
      image: Pongal2k25_Img_5,
      altText: "",
      caption: "",
      id: "pongal2k25",
    },
    {
      image: Pongal2k25_Img_7,
      altText: "",
      caption: "",
      id: "pongal2k25",
    },
  ];
  const ScienceExpo = [
    {
      image: ScienceExpo_Img_1,
      altText: "",
      caption: "",
      id: "ScienceExpo",
    },
    {
        image: ScienceExpo_Img_2,
        altText: "",
        caption: "",
        id: "ScienceExpo",
      },
      {
        image: ScienceExpo_Img_3,
        altText: "",
        caption: "",
        id: "ScienceExpo",
      },
      {
        image: ScienceExpo_Img_4,
        altText: "",
        caption: "",
        id: "ScienceExpo",
      },
      {
        image: ScienceExpo_Img_5,
        altText: "",
        caption: "",
        id: "ScienceExpo",
      },
      {
        image: ScienceExpo_Img_6,
        altText: "",
        caption: "",
        id: "ScienceExpo",
      },

      {
        image: ScienceExpo_Img_7,
        altText: "",
        caption: "",
        id: "ScienceExpo",
      },
  ];
   const PlacementTrainning =[
    {
      image: PlacementTrainning_Img_1,
      altText: "",
      caption: "",
      id: "placement-",
    },
    {
      image: PlacementTrainning_Img_2,
      altText: "",
      caption: "",
      id: "placement-",
    },
    {
      image: PlacementTrainning_Img_3,
      altText: "",
      caption: "",
      id: "placement-",
    },
    {
      image: PlacementTrainning_Img_4,
      altText: "",
      caption: "",
      id: "placement-",
    },
    {
      image: PlacementTrainning_Img_5,
      altText: "",
      caption: "",
      id: "placement-",
    },
   ];
  return (
    <>
      <Helmet>
        <title> Gallery-JNCW </title>
      </Helmet>

      <div className="container">
        <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h5 class="section-title bg-white text-center text-primary px-3">
            Gallery
          </h5>
          <h4 class="mb-5">JNCW-2025 Moments</h4>
        </div>
{/* <GalleryPage/> */}
<EventContainer
          data={PlacementTrainning}
          header="Placement Trainning - Leadership Event for Aspiring Young Women"
          id="placement-1"
        />
        <EventContainer
          data={ScienceExpo}
          header="Science Expo 2k25"
          id="scienceexpo"
        />

        <EventContainer
          data={Pongal2k25}
          header="Pongal Celebrations-2025"
          id="pongal2k25"
        />
      </div>
    </>
  );
};

export default Moments2025;
