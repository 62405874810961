import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./Training.css";
import trainingEvents from "./TrainingEvents";
import Modal from "../../../modal/modal";

const PlacementTraining = () => {
  return (
  
<Modal class="modal-dialog modal-dialog-scrollable">

</Modal>
  );
};

export default PlacementTraining;
