import { AdmissionMarquee } from "./admissionMrq/index";
import { NavHead } from "./navHead";
import React, { useState } from "react";
import Logo from "../../assets/images/logo/JSAGROUPS.png"
import "./navBar.scss";
import { Link } from "react-router-dom";
import  {NewsMarquee} from './Newsmarq/index';

const NavBar = () => {
  const [aboutDropdownOpen, setAboutDropdownOpen] = useState(false);
  const [pagesDropdownOpen, setPagesDropdownOpen] = useState(false);
  const [AcademicsDropdownOpen, setAcademicsDropdownOpen] = useState(false);
  const [galleryDropdownOpen,setGalleryDropdownOpen] = useState(false);
  const [AmenitiesDropdownOpen,setAmenitiesDropdownOpen] =useState(false);
  const [PlacementDropdownOpen,setPlacementDropdownOpen] = useState(false);
  const [GroupDropdownOpen,setGroupDropdownOpen] = useState(false);

  const toggleAmenitiesDropdown = () => {
    setAmenitiesDropdownOpen(!AmenitiesDropdownOpen);
  };
  const toggleGroupDropdown = () => {
    setGroupDropdownOpen(!GroupDropdownOpen);
  };

  const toggleGalleryDropdown = () => {
    setGalleryDropdownOpen(!galleryDropdownOpen);
  };
  const togglePlacementDropdown = () => {
    setPlacementDropdownOpen(!PlacementDropdownOpen);
  };

  const toggleAboutDropdown = () => {
    setAboutDropdownOpen(!aboutDropdownOpen);
  };

  const togglePagesDropdown = () => {
    setPagesDropdownOpen(!pagesDropdownOpen);
  };

  const toggleAcademicsDropdown = () => {
    setAcademicsDropdownOpen(!AcademicsDropdownOpen);
  };

  return (
    <>
      <div id="nav">
        <AdmissionMarquee />
        <NavHead />
      </div>
      <NewsMarquee/>
      <nav className="navbar navbar-expand-lg navbar-light shadow sticky-top p-0">
        <a href="https://jsagroups.in/" className="navbar-brand d-flex align-items-center px-4 px-lg-5">
          <h6 className="navbar-brand d-flex align-items-center">
            <img src={Logo} alt="..." width="90" height="60" />
         JSA GROUP OF INSTITUTIONS 
          </h6>
        </a>
        <button type="button" className="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto p-4 p-lg-0">
          <a href="/" className="nav-item nav-link">Home</a>
          <div className="nav-item dropdown" onMouseEnter={toggleGroupDropdown} onMouseLeave={toggleGroupDropdown}>
              <a href="# " className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Our Groups</a>
              <div className={"dropdown-menu fade-down m-0" + (GroupDropdownOpen ? " show" : "")}>
              {/* <a className="dropdown-item" href="https://jsagroups.in/">jsagroups</a> */}
                <a className="dropdown-item" href="https://jsasiddha.in/">JSA Medical College for Siddha and Research Centre</a>
                <a className="dropdown-item" href="https://jsaagri.in/"> JSA College of Agriculture and Technology </a>
             
                
              
              </div>
            </div>
           
            <div className="nav-item dropdown" onMouseEnter={toggleAboutDropdown} onMouseLeave={toggleAboutDropdown}>
              <a href="# " className="nav-link dropdown-toggle" data-bs-toggle="dropdown">About</a>
              <div className={"dropdown-menu fade-down m-0" + (aboutDropdownOpen ? " show" : "")}>
              <a className="dropdown-item" href="/vision&mission">Vision & Mission</a>
                <a className="dropdown-item" href="/chairman">Chairman</a>
                <a className="dropdown-item" href="/secretary">Secretary</a>
                <a className="dropdown-item" href="/executive-director">Executive Director</a>
                <a className="dropdown-item" href="/principal">Principal</a>
                
              
              </div>
            </div>
            <div className="nav-item dropdown" onMouseEnter={toggleAcademicsDropdown} onMouseLeave={toggleAcademicsDropdown}>
              <a href="# " className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Academics</a>
              <div className={"dropdown-menu fade-down m-0" + (AcademicsDropdownOpen ? " show" : "")}>
                {/* <a href="/courses" className="dropdown-item">Department</a> */}
                <a href="/courses" className="dropdown-item">Department</a>
                <a href="/academics/academic-calender" className="dropdown-item">Academic Calendar</a>
                <a href="/facultydetails" className="dropdown-item">Faculty</a>
                <a className="dropdown-item" href="/university-rankers">University Rankers</a>
              
              </div>
            </div>
            <div className="nav-item dropdown" onMouseEnter={toggleAmenitiesDropdown} onMouseLeave={toggleAmenitiesDropdown}>
              <a href="# " className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Amenities</a>
              <div className={"dropdown-menu fade-down m-0" + (AmenitiesDropdownOpen ? " show" : "")}>
              <a href='/academics/infrastrutre' className="dropdown-item">Infrastructure</a>
              <a href='/laboratories' className="dropdown-item">Laboratories</a>
                <a className="dropdown-item" href="/amenities/smart-class">Smart class</a>
                <a className="dropdown-item" href="/amenities/library">Library</a>
                <a className="dropdown-item" href="/amenities/transport">Transport</a>
                <a className="dropdown-item" href="/amenities/hostel">Hostel</a>
                <a className="dropdown-item" href="/amenities/mess-canteen">Mess</a>
              </div>
            </div>
            <div className="nav-item dropdown" onMouseEnter={togglePagesDropdown} onMouseLeave={togglePagesDropdown}>
              <a href="# " className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Event</a>
              <div className={"dropdown-menu fade-down m-0" + (pagesDropdownOpen ? " show" : "")}>
                <a href="/sports" className="dropdown-item">Sports</a>
                <a href="/nss-yrc-rrc" className="dropdown-item">NSS & YRC & RRC</a>
              </div>
            </div>
            <a href="/news&updates" className="nav-item nav-link">News</a>
            <div className="nav-item dropdown" onMouseEnter={toggleGalleryDropdown} onMouseLeave={toggleGalleryDropdown}>
              <a href="# " className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Gallery</a>
              <div className={"dropdown-menu fade-down m-0" + (galleryDropdownOpen ? " show" : "")}>
              {/* <a className="dropdown-item" href="/gallery/Moments2025">2025</a> */}
              <a className="dropdown-item" href="/gallery/2025">2025</a>
                <a className="dropdown-item" href="/gallery/2024">2024</a>
                <a className="dropdown-item" href="/gallery/2023">2023</a>
                <a className="dropdown-item" href="/gallery/2022">2022</a>
                <a className="dropdown-item" href="/gallery/2021">2021</a>
                <a className="dropdown-item" href="/gallery/2020">2020</a>
                <a className="dropdown-item" href="/gallery/2019">2019</a>
              </div>
            </div>
     {/* <div className="nav-item dropdown" onMouseEnter={togglePlacementDropdown} onMouseLeave={togglePlacementDropdown}>
  <a href="# " className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Placement</a>
  <div className={"dropdown-menu fade-down m-0" + (PlacementDropdownOpen ? " show" : "")}>
    <a className="dropdown-item" href="/placement">About Placement</a>
    <a className="dropdown-item" href="/placement/report">Placement Report</a>
    <a className="dropdown-item" href="/placement-Training">Training Programs</a>
    <a className="dropdown-item" href="/placement/recruiters">Top Recruiters</a>
    <a className="dropdown-item" href="/placement/students-testimonials">Student Testimonials</a>
    <a className="dropdown-item" href="/placement/gallery">Placement Gallery</a>
  </div>
</div> */}

            {/* <div className="nav-item dropdown" onMouseEnter={togglePlacementDropdown} onMouseLeave={togglePlacementDropdown}>
              <a href="# " className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Placement</a>
              <div className={"dropdown-menu fade-down m-0" + (PlacementDropdownOpen ? " show" : "")}>
                <a className="dropdown-item" href="/placement"> About Placement</a>
                <a className="dropdown-item" href="/placement-report"> Placement Report</a>
                <a className="dropdown-item" href=" "> Placement Gallery</a>
                <a className="dropdown-item" href="/gallery/2022">2022</a>
                <a className="dropdown-item" href="/gallery/2021">2021</a>
                <a className="dropdown-item" href="/gallery/2020">2020</a>
                <a className="dropdown-item" href="/gallery/2019">2019</a>
              </div>
            </div> */}
            <a href="/placement" className="nav-item nav-link">Placement</a>
            <a href="/nirf" className="nav-item nav-link">NIRF</a>
            {/* <a href="/alumini" className="nav-item nav-link">Alumini</a> */}
            {/* <a href="/naac" className="nav-item nav-link">NAAC</a> */}
            {/* <a href="/naac" className="nav-item nav-link">IQAC</a>
             <a href="/online-payment" className="nav-item nav-link"> Payment</a> */}
            <a href="/contactus" className="nav-item nav-link">Contact</a>                                               
          </div>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSdd8pOpDYoSrlfvTbL-y8x7swoVqKoIZ4xTiz-hTlOmjxvTZA/viewform" className="btn btn-warning ">Admission Enquiry
                 <i className="fa fa-arrow-right"></i>
          </a>
        </div>
      </nav>
      
    </>
  );
};

export default NavBar;
