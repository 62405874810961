import React from "react";
import News_1 from "../../../assets/images/gallery/2025/jan/sci/Sci (2).jpeg";
import News_2 from "../../../assets/images/gallery/2025/jan/sci/Sci (4).jpeg";
import News_3 from "../../../assets/images/gallery/2025/jan/pongal festival/Img  (3).jpeg";
import { Link } from "react-router-dom";


const CardGrid = () => {
  const cardsData = [
    {
      url: News_1,
      title: "Science Expo 2k25",
      text: "",
      updatedTime: "1 Week ago",
    },
    {
      url: News_2,
      title: " Science Expo 2k25",
      text: "",
      updatedTime: "1 weeks ",
    },
    {
      url: News_3,
      title: "Pongal Celebrations",
      text: "",
      updatedTime: "3 week ",
    },
    // {
    //   url: News_4,
    //   title: "National Voters day rally",
    //   text: "National Voters day rally organized at Tholudur on 25th January 2025",
    //   updatedTime: "1 week ",
    // },
    // {
    //   url: News_5,
    //   title: "Pongal Celebration",
    //   text: "JSACAT celebrated Pongal festival on 3 January 2025",
    //   updatedTime: "2 week ",
    // },
  ];

  return (
    <div className="row row-cols-1 row-cols-md-3 g-4">
      {cardsData.map((card, index) => (
        <Card key={index} {...card} />
      ))}
    </div>
  );
};

const Card = ({  url, title, text, updatedTime }) => {
    return (
      <div className="col">
        <div className="card h-100">
          <img src={ url} className="card-img-top" alt="Card" />
          <div className="card-body">
            <h5 className="card-title">{title}</h5>
            <p className="card-text">{text}</p>
            <Link to="/gallery/2025" class="btn btn-primary">More Details </Link>
          </div>
          <div className="card-footer">
            <small className="text-muted">Last updated {updatedTime} ago</small>
          </div>
        </div>
      </div>
    );
  };
export default CardGrid;
