import React from "react";

const PlacementPage = () => {
    return (
        <div>
            <div className="hero bg-primary text-white text-center py-5" style={{ backgroundImage: "url('https://source.unsplash.com/1600x600/?education,students')", backgroundSize: "cover" }}>
                <h1>Welcome to Our Placement Portal</h1>
                <p>Providing the best career opportunities for students</p>
            </div>

            <div className="container mt-4">
                <div className="row">
                    <div className="col-md-8">
                        <h2>Latest News</h2>
                        <ul className="list-group">
                            <li className="list-group-item">Company XYZ is hiring freshers! <a href="#">Apply Now</a></li>
                            <li className="list-group-item">Upcoming Job Fair on March 15th <a href="#">Register Here</a></li>
                            <li className="list-group-item">New Python Certification Course Launched <a href="#">Enroll Today</a></li>
                        </ul>
                    </div>
                    <div className="col-md-4 bg-light p-3">
                        <h4>Quick Links</h4>
                        <ul>
                            <li><a href="#">Job Openings</a></li>
                            <li><a href="#">Internship Programs</a></li>
                            <li><a href="#">Training Materials</a></li>
                            <li><a href="#">Alumni Success Stories</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <footer className="bg-dark text-white text-center py-3 mt-4">
                &copy; 2025 EduPlacement. All Rights Reserved.
            </footer>
        </div>
    );
};

export default PlacementPage;
