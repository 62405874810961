const trainingEvents = [
    {
      id: 1,
      title: " Leadership Quality in YOU!",
      description: "The recently conducted leadership event for aspiring young women was a powerful gathering of ambition, inspiration, and growth. Designed to equip participants with the skills and confidence to lead, the event featured dynamic keynote speakers, interactive workshops, and insightful panel discussions with accomplished women leaders.From mastering effective communication to building resilience in the face of challenges, attendees gained valuable insights and practical strategies to shape their leadership journeys. The networking sessions fostered meaningful connections, while hands-on activities encouraged teamwork, problem-solving, and innovative thinking.This transformative experience left participants motivated to break barriers, embrace leadership opportunities, and create a lasting impact in their fields. The event was not just about learning—it was about igniting the potential within every young woman to lead with purpose and passion.",
      date: "📅 DATE: 27 Jan 2025",
      location: "📍 College Auditorium",
      highlights: [
        { icon: "📢", title: "Communication Skills", desc: "Master effective speaking & active listening techniques." },
        { icon: "🚀", title: "Building Resilience", desc: "Learn to tackle challenges with confidence." },
        { icon: "🤝", title: "Networking & Teamwork", desc: "Connect with leaders & develop collaborative skills." },
        { icon: "💡", title: "Problem Solving", desc: "Sharpen your analytical thinking & decision-making." }
      ]
    },
    {
      id: 2,
      title: "Tech Innovation Bootcamp",
      description: "Learn AI, ML, and Cloud Computing.",
      date: "📅 DATE: 10 Feb 2025",
      location: "📍 Tech Park Auditorium",
      highlights: [
        { icon: "🤖", title: "AI & Machine Learning", desc: "Explore deep learning & AI applications." },
        { icon: "☁️", title: "Cloud Computing", desc: "Learn cloud technologies like AWS & Azure." },
        { icon: "📊", title: "Data Analytics", desc: "Hands-on training with real-world datasets." },
        { icon: "🖥️", title: "Full-Stack Development", desc: "Build modern web applications." }
      ]
    }
  ];
  
  export default trainingEvents;
  