import React from "react";
import "./Sidebar.css";

const Sidebar = () => {
  const studentLinks = [
    { name: "Live Programs", url: "#" },
    { name: "Part Time & Internships", url: "#" },
    { name: "Resources", url: "#" },
    { name: "Students Placed | 2023 - 2024", url: "#", isNew: true },
    { name: "Passed Outs", url: "#" },
  ];

  const TrainingPlacement = [
    { title: "Circular 1 - 13th April 2022", url: "#" },
    { title: "Internship Annual Rankings '21", url: "#" },
  ];

  const annualreport = [
    { title: "No.of Student Placed so far(2024-2025)", url: "#" },
    { title: "Internship Annual Rankings '21", url: "#" },
  ];

  const EnquiryContact =[
    { title: "Mail:jncwplacementcell@gmail.com ", url: "#" },
   
  ]

  return (
    <div className="sidebar">
      <div className="p-3 bg-light border rounded">
        <h5 className="border-bottom pb-2">Students Corner</h5>
        <ul className="list-unstyled">
          {studentLinks.map((link, index) => (
            <li key={index}>
              <a href={link.url}>
                {link.name}{" "}
                {link.isNew && <span className="text-warning fw-bold">NEW!</span>}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="p-3 mt-3 bg-light border rounded">
        <h5 className="border-bottom pb-2">Training & Placement</h5>
        <ul className="list-unstyled">
          {TrainingPlacement.map((item, index) => (
            <li key={index}>
              <a href={item.url}>{item.title}</a>
            </li>
          ))}
        </ul>
      </div>
      <div className="p-3 mt-3 bg-light border rounded">
        <h5 className="border-bottom pb-2">Annualreport</h5>
        <ul className="list-unstyled">
          {annualreport.map((item, index) => (
            <li key={index}>
              <a href={item.url}>{item.title}</a>
            </li>
          ))}
        </ul>
      </div>

           <div className="p-3 mt-3 bg-light border rounded">
  <h5 className="border-bottom pb-2">Enquiry & Contact</h5>
  <div style={{ maxWight:"100% ", maxHeight: "200px", overflowY: "auto" }}>
    <ul className="list-unstyled">
      {EnquiryContact.map((item, index) => (
        <li key={index}>
          <a href={item.url}>{item.title}</a>
        </li>
      ))}
    </ul>
  </div>
</div>

    </div>
  );
};

export default Sidebar;
