import { Helmet } from "react-helmet-async";
import "./news.css";
import CardGrid from "./card";


const NewsContent = () => {

  
  return (
    <>
      <Helmet>
        <title>News & Upcoming- JNCW</title>
      </Helmet>
      
      <div className="container mt-4">

      <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h4 class="section-title bg-white text-center text-primary px-3">
          News & Announcement
          </h4>
          {/* <h4 class="mb-5"> JNCW-2025 Moments </h4> */}
        </div>
        {/* <h5 className="text-uppercase bg-heading ">Upcoming Event</h5> */}
      
      <div className="container">
      <h5 className="text-uppercase bg-heading ">Latest Events </h5>
      <CardGrid />
      </div>
    </div>
    </>
  );
};

export default NewsContent;
