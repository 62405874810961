import React from "react";
import Sidebar from "./compents/Sidebar";
import DirectorDesk from "./compents/DirectorDesk";
import ImageGallery from "./compents/ImageGallery";
import MainContent from "./compents/MainContent";
import "./compents/PlacementPage.css";

const PlacementPage = () => {
  return (
    <div className="container mt-4 placement-page">
      <div className="text-center">
        <h4 className="section-title bg-white text-primary px-3">Placement</h4>
      </div>

      <div className="row">
        <div className="col-md-3"><Sidebar /></div>
        <MainContent />
        <div className="col-md-3">
        <ImageGallery />
          <DirectorDesk />
         
        </div>
      </div>

      {/* Announcement Bar */}
      {/* <div className="bg-dark text-warning text-center py-2 mt-4 announcement-bar">
        <marquee>The Department of Business Administration hosts "Invogue Branding" on March 12th, 2025.</marquee>
      </div> */}
    </div>
  );
};

export default PlacementPage;
