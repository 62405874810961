// import React from "react";
// import "./MainContent.css";

// const MainContent = () => {
//   return (
//     <div className="col-md-6 main-content">
//       <h5 className="fw-bold text-center border-bottom pb-2">
//         The Placement Cell: Empowering Futures
//       </h5>
//       <p>
//         At Jawaharlal Nehru Women’s College, we take enormous satisfaction in
//         molding the careers of our students by providing them with a strong
//         foundation in academics and an active placement unit that bridges the
//         gap between education and industry.{" "}
//       </p>
//       <p>
//         Our placement cell is dedicated to enhancing student’s employability
//         through a structured training approach, ensuring they are industry-ready
//         upon graduation.{" "}
//       </p>
//       <p>
//         By offering Intense training programs, ensuring that students are not
//         just academically proficient but also equipped with the essential skills
//         to excel in their professional journeys. The success of the placement
//         cell is reflected in its ability to transform aspiring students into
//         empowered professionals who are ready to make a mark in the corporate.
//       </p>
//       <h5 className="fw-bold border-bottom pb-2">
//         Comprehensive Training for Career Success
//       </h5>
//       <p>The placement cell offers a range of training programs to prepare students for the competitive job market. These include:</p>
           
//       <ul>
//         <li>
//           <h6  className="fw-bold">Communication Skills Development –</h6> Training in spoken
//           English, public speaking, and professional etiquette.
//         </li>
//         <li>
//           <h6  className="fw-bold">Soft Skills Training –</h6> Workshops on leadership, teamwork, and
//           time management.
//         </li>
//         <li>
//           <h6  className="fw-bold">Resume Building & Career Guidance –</h6> Expert-led sessions on
//           crafting resumes and career counseling.
//         </li>
//         <li>
//           <h6  className="fw-bold">Mock Interviews & Group Discussions –</h6> Regular interview and
//           discussion practice.
//         </li>
//       </ul>
//       <h3 className="fw-bold">Industrial Exposure & Hands-on Experience</h3>
//       <p>Understanding the importance of practical experience, we facilitate industrial training programs and internships in collaboration with reputed companies. These programs help students gain firsthand knowledge of workplace dynamics and professional expectations.</p>
//       <ul>
//         <li>
//           <h6  className="fw-bold">Industry-Integrated Workshops </h6> 
//           Industry experts are invited to conduct seminars and interactive sessions on current trends and technologies, keeping students updated with market demands.
//         </li>
//         <li>
//           <h6  className="fw-bold">Internships & Live Projects </h6> 
//           We collaborate with top companies and organizations to offer internships and live projects, enabling students to gain real-world exposure before entering the workforce.
//         </li>
//         <li>
//           <h6  className="fw-bold">Corporate Tie-ups & Placement Drives </h6> 
//           Our strong network with leading firms allows us to organize placement drives, providing students with ample job opportunities in their respective fields.
//         </li>
//              </ul>
//              <h5 className="fw-bold border-bottom pb-2">
//              A Stepping Stone to Success
//       </h5>
//       <p>Our Placement cell,  is committed to empower young women by equipping them with the necessary skills to achieve their career aspirations. Through our Dynamic training approach, industry collaborations, and unwavering support, we ensure that our students step into the professional world with confidence and competence.</p>
//        <p>We invite students to make the most of the resources available, participate actively in the training programs, and embark on a journey of professional excellence. </p>
//        <p>The future is bright, and we are here to help you shine!</p>
//     </div>
//   );
// };

// export default MainContent;
import { useState } from "react";

const  MainContent = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="col-md-6 main-content">
      <h5 className="fw-bold text-center border-bottom pb-2">
        The Placement Cell: Empowering Futures
      </h5>
      <p>
        At Jawaharlal Nehru Women’s College, we take enormous satisfaction in
        molding the careers of our students by providing them with a strong
        foundation in academics and an active placement unit that bridges the
        gap between education and industry.
      </p>
      <p>
        Our placement cell is dedicated to enhancing students' employability
        through a structured training approach, ensuring they are industry-ready
        upon graduation.
      </p>

      {!expanded && (
        <button className="btn btn-primary mt-2" onClick={() => setExpanded(true)}>
          Read More
        </button>
      )}

      {expanded && (
        <>
          <p>
            By offering intense training programs, ensuring that students are
            not just academically proficient but also equipped with the essential
            skills to excel in their professional journeys. The success of the
            placement cell is reflected in its ability to transform aspiring
            students into empowered professionals who are ready to make a mark
            in the corporate world.
          </p>
          <h5 className="fw-bold border-bottom pb-2">
            Comprehensive Training for Career Success
          </h5>
          <p>The placement cell offers a range of training programs to prepare students for the competitive job market. These include:</p>

          <ul>
            <li>
              <h6 className="fw-bold">Communication Skills Development –</h6> Training in spoken
              English, public speaking, and professional etiquette.
            </li>
            <li>
              <h6 className="fw-bold">Soft Skills Training –</h6> Workshops on leadership, teamwork, and
              time management.
            </li>
            <li>
              <h6 className="fw-bold">Resume Building & Career Guidance –</h6> Expert-led sessions on
              crafting resumes and career counseling.
            </li>
            <li>
              <h6 className="fw-bold">Mock Interviews & Group Discussions –</h6> Regular interview and
              discussion practice.
            </li>
          </ul>

          <h5 className="fw-bold">Industrial Exposure & Hands-on Experience</h5>
          <p>
            Understanding the importance of practical experience, we facilitate industrial training programs and internships in collaboration with reputed companies.
          </p>

          <ul>
            <li>
              <h6 className="fw-bold">Industry-Integrated Workshops</h6>
              Industry experts are invited to conduct seminars and interactive sessions on current trends and technologies.
            </li>
            <li>
              <h6 className="fw-bold">Internships & Live Projects</h6>
              We collaborate with top companies and organizations to offer internships and live projects.
            </li>
            <li>
              <h6 className="fw-bold">Corporate Tie-ups & Placement Drives</h6>
              Our strong network with leading firms allows us to organize placement drives, providing students with ample job opportunities.
            </li>
          </ul>

          <h5 className="fw-bold border-bottom pb-2">
            A Stepping Stone to Success
          </h5>
          {/* <p>
            Our Placement cell is committed to empowering young women by equipping them with the necessary skills to achieve their career aspirations.
          </p>
          <p>
            We invite students to make the most of the resources available, participate actively in the training programs, and embark on a journey of professional excellence.
          </p> */}
          <p>The future is bright, and we are here to help you shine!</p>

          <button className="btn btn-secondary mt-2" onClick={() => setExpanded(false)}>
            Read Less
          </button>
        </>
      )}
    </div>
  );
};

export default  MainContent;
